const messages = {
  gb: {
    member: {
      fullName: 'Full Name',
      gender: 'Gender',
      birthDate: 'Birth Date',
      nationality: 'Nationality',
      idNumber: 'Passport No.',
      occupation: 'Occupation',
      address: 'Address',
      phoneNumber: 'Phone Number',
      prevPlace: 'Previos Place',
      nextDest: 'Next Destination'
    },
    preCheckIn: {
      idVerified: 'ID Picture Verfied'
    },
    checkIn: {
      btn_id_info: 'Step 3: Guest Info',
      btn_face_upload: 'Step 2: Upload Portrait Image',
      btn_id_upload: 'Step 1: Upload Passport Image',
      btn_id_take: 'Take ID Picture',
      btn_id_wait: 'Need the host for ID Verification',
      btn_id_verified: 'ID Picture Verfied',
      btn_face_take: 'Take Face Picture',
      btn_face_retake: 'Need to re-take the Face Picture',
      btn_rekog_wait: 'Need the host for ID Recognition',
      btn_rekog_matches: 'ID Matches Face',
      step_label_fullname_input: 'Please set your full name first.',
      step_label_rekog_matches: 'ID Matches Face',
      step_label_face_take: 'Take Face Picture for ID recognition',
      step_label_pictures_take: 'Take Face and ID Pictures',
      step_label_rekog_wait: 'Need the host for ID Recognition',
    },
    camera: {
      capture: 'Take a picture'
    },
    menu: {
      gb: 'EN',
      hk: 'CN',
      jp: 'JP'
    },
    about: {
      timeline_add_reservation: 'After the reservation is confirmed the host adds the information with manual, automatic or batch-import approaches.',
      timeline_upload_id: 'The guest retreives the login information of GoCheckIn. At the GoCheckIn Site the identity pictures of all the members are taken and uploaded.',
      timeline_verify_id: 'The host verifies the identity pictures and other mandatory information.',
      timeline_guidebook: 'After the verification by the host the guest will be able to get the guidebook.',
      timeline_match_id: 'At the accommodation each members takes a face picture to match the previously taken identity picture.',
      timeline_host_verify: 'Self Check-in will be done without the intervention of the host unless the special situations listed below: 1) The host is unable to verify all the identities before the guest arrives. 2) Any members re-takes the identity picture during identity-maching at the accommodation. 3) Special cases such as check-in of separated groups or the number of guests has to be changed.',
      timeline_key: 'Identity-matching successfully completed，The guest manages to check in and get the key info.',
      timeline_guest_title_remote: 'Guest Action (Before the trip)',
      timeline_guest_title_site: 'Guest Action(At the accommodation)',
      timeline_host_title: 'Host Operation',
    }
  },
  hk: {
    member: {
      fullName: '姓名',
      gender: '性別',
      birthDate: '出生日期',
      nationality: '國籍',
      idNumber: '護照號碼',
      occupation: '職業',
      address: '聯繫地址',
      phoneNumber: '電話號碼',
      prevPlace: 'Previos Place',
      nextDest: 'Next Destination'
    },
    preCheckIn: {
      idVerified: '證件圖片已确认'
    },
    checkIn: {
      btn_id_info: '請輸入個人信息',
      btn_id_take: '請拍攝身分照片',
      btn_id_wait: '需要房東確認身分照片',
      btn_id_verified: '身分照片已確認',
      btn_face_take: '請拍攝面部照片',
      btn_face_retake: '面部識別需要再次拍攝照片',
      btn_rekog_wait: '需要房東進行身分確認',
      btn_rekog_matches: '面部識別成功',
      step_label_fullname_input: '請先輸入姓名',
      step_label_rekog_matches: '面部識別成功',
      step_label_face_take: '需要進行面部識別',
      step_label_pictures_take: '拍攝面部照片和身分照片',
      step_label_rekog_wait: '需要房東進行身分確認',
    },
    camera: {
      capture: '點擊拍照'
    },
    menu: {
      gb: '英',
      hk: '中',
      jp: '日'
    },
    about: {
      timeline_add_reservation: '房東獲取客人預約信息後，使用手動，批量或者自動等方式使用GoCheckIn，用手动，批量，自动等方式登记预约信息。',
      timeline_upload_id: '入住前客人獲得GoCheckIn帳號信息。在GoCheckIn網站登錄後，拍攝上傳入住成員的有效身分證件照片以及其他以及其他法規規定的信息。',
      timeline_verify_id: '房東確認身分證件照片是否清晰可用，以及其他信息是否正確有效。',
      timeline_guidebook: '獲得房東確認所有成員的身分證件後，客人可以從GoCheckIn網站獲取入住導航等信息。',
      timeline_match_id: '客人到達住宿地點，所有成員都拍攝了個人照片，以進行與证件照之間的人臉對比身分识别。',
      timeline_host_verify: '以下特殊情況之外，無需房東人工干預即可完成自動入住登記：1.客人到達住宿地點之前，房東未能確認所有入住客人成員的有效身分證件。2.在住宿地點進行身分識別時，客人從新拍攝了部分或所有成員的的身分證件。3.客人有分批入住登記或成員數量臨時變化等其他特殊情況。',
      timeline_key: '身分識別順利完成，客人獲取鑰匙等信息，成功登記入住。',
      timeline_guest_title_remote: '客人所執行的操作（開始行程之前）',
      timeline_guest_title_site: '客人所執行的操作（到達入住地點時）',
      timeline_host_title: '房東的操作',
    }
  },
  jp: {
    member: {
      fullName: '氏名',
      gender: '性別',
      birthDate: '生年月日',
      nationality: '国籍',
      idNumber: 'パスポート番号',
      occupation: '職業',
      address: '住所',
      phoneNumber: '電話番号',
      prevPlace: '前泊地',
      nextDest: '行先地'
    },
    preCheckIn: {
      idVerified: '證件圖片已确认'
    },
    checkIn: {
      btn_id_info: '個人情報を入力',
      btn_id_take: '身分写真を撮る',
      btn_id_wait: 'ホストで身分写真確認が必要',
      btn_id_verified: '身分写真確認済み',
      btn_face_take: '顔写真を撮る',
      btn_face_retake: '顔認証のため顔写真を再度撮る',
      btn_rekog_wait: 'ホストで身分確認が必要',
      btn_rekog_matches: '顔認証済み',
      step_label_fullname_input: 'まずお名前を入力ください',
      step_label_rekog_matches: '顔認証済み',
      step_label_face_take: '顔写真を（再度）撮るのが必要',
      step_label_pictures_take: '顔写真と身分写真が必要',
      step_label_rekog_wait: 'ホストで身分確認が必要',
    },
    camera: {
      capture: 'クリック'
    },
    menu: {
      gb: '英',
      hk: '中',
      jp: '日'
    },
    about: {
      timeline_add_reservation: '宿泊予約確定後、ホストが手動、自動、またはインポートの方式でGoCheckInに情報を登録します。',
      timeline_upload_id: 'ゲストがGoCheckInサイトのログイン情報を入手しました。ログイン後，各宿泊メンバーの身分証明書の写し、およびその他法令に基づいた情報を登録します。',
      timeline_verify_id: 'ホストが身分証明書とその他の情報を確認します。',
      timeline_guidebook: 'ホストによる身分証明書の確認が完了しましたら、ゲストがGoCheckInサイトで宿泊施設のガイドブック情報を取得できます。',
      timeline_match_id: '宿泊施設に到着後、各メンバーが顔写真を取り，顔認証による身分確認を行います。',
      timeline_host_verify: '以下の特殊状況以外に、無人チェックイン（セルフチェックイン）がスムーズに完成できます。１．チェックインまでゲストのすべての身分証明書をホストが確認できていない場合。２．宿泊施設にて顔写真撮影の際，身分証明書の写しが変更された場合。３．宿泊メンバーが別々のタイミングでチェックインする場合や、突然な人数変更の場合等特殊状況',
      timeline_key: '顔認証による身分確認でき、ゲストが入居のカギ情報を取得し、セルフチェックイン完了します。',
      timeline_guest_title_remote: '宿泊者（ゲスト）のアクション（旅を始める前）',
      timeline_guest_title_site: '宿泊者（ゲスト）のアクション（施設に到着時）',
      timeline_host_title: '宿泊施設管理者（ホスト）側のオペレーション',
    }
  }
}

export default messages