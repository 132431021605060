import { Storage } from 'aws-amplify'

export default {

  async put (imgName, imgBufferArray) {

    const rtn = await Storage.put(imgName, imgBufferArray, 
      {
        level: 'private',
        contentType: 'image/jpeg'
      })
      .catch(e => console.log('storage.put',e))

    return rtn
  },
  async get (imgName) {
    const url = await Storage.get(imgName, {level: 'private'})
      .catch(e => console.log('storage.get',e))

    return url
  }
}
