export const getReservationsByUsername = `query GetReservationsByUsername($username: String!, $currentDate: AWSDate!) {
  getReservationsByUsername(username: $username, currentDate: $currentDate) {
    reservationCode
    listingId
    hostId
    username
    email
    phoneNo
    bookingId
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`
/*
export const updateReservationStatus = `mutation UpdateReservationStatus($input: MutateReservationInput!, $expectedLastUpdateOn: AWSDateTime!) {
  updateReservationStatus(input: $input, expectedLastUpdateOn: $expectedLastUpdateOn) {
    hostId
    listingId
    reservationCode
    bookingId
    phoneNo
    username
    email
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    keyInfo
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    notificationResponses {
      result
      messageType
      messageDate
      errorCode
      error
    }
    referrer
    lastUpdateOn
  }
}
`
*/
/*
export const updateIdVerified = `mutation UpdateIdVerified($input: MutateReservationInput!) {
  updateIdVerified(input: $input)
  {
    reservationCode
    listingId
    hostId
    username
    email
    phoneNo
    bookingId
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    notificationResponses {
      result
      messageType
      messageDate
      errorCode
      error
    }
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`
*/

export const verifyMembers = `mutation VerifyMembers($input: MutateReservationInput!, $hostVerifying: Boolean!) {
  verifyMembers(input: $input, hostVerifying: $hostVerifying)
  {
    reservationCode
    listingId
    hostId
    username
    email
    phoneNo
    bookingId
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const getMembers = `query GetMembers($reservationCode: String!) {
  getMembers(reservationCode: $reservationCode) {
    reservationCode
    memberNo
    imgBucket
    faceImgKey
    idImgKey
    similarity
    fullName
    gender
    birthDate
    address
    phoneNumber
    idNumber
    nationality
    occupation
    prevPlace
    nextDest
    memberKeyItem {
      roomCode
      keyInfo
    }
    keyNotified
    lastUpdateOn
  }
}
`

export const updateMember = `mutation UpdateMember($input: UpdateMemberInput!) {
  updateMember(input: $input)
  {
    reservationCode
    memberNo
    imgBucket
    faceImgKey
    idImgKey
    similarity
    fullName
    gender
    birthDate
    address
    phoneNumber
    idNumber
    nationality
    occupation
    prevPlace
    nextDest
    memberKeyItem {
      roomCode
      keyInfo
    }
    keyNotified
    lastUpdateOn
  }
}
`