import appsync from '@/api/appsync'
import storage from '@/api/storage'
import * as faceapi from 'face-api.js';

const state = {
  reservation: null,
  reservations: [],
  members: [],
  currentStep: 1,
  currentMember: null,
  guideUrl: null
}

const mutations = {
  setCurrentMember(state, val) {
    state.currentMember = val
  }, 
  setReservations(state, val) {
    state.reservations = val
  },  
  setReservation(state, val) {
    state.reservation = val
  },
  setMembers(state, val) {
    state.members = val
  },
  setCurrentStep(state, val) {
    state.currentStep = val
  },
  setGuideUrl(state, val) {
    state.guideUrl = val
  },
}

const getFaceBuffers = async (image, detections) => {
  const faceBuffers = [];
  for (const detection of detections) {
    const box = detection.box;

    // Calculate margin size
    const marginX = 20;
    const marginY = 40;

    // Adjust bounding box with margin
    const x = Math.max(0, box.x - marginX);
    const y = Math.max(0, box.y - marginY);
    const width = Math.min(box.width + 2 * marginX, image.width - x);
    const height = Math.min(box.height + 2 * marginY, image.height - y);

    const faceCanvas = document.createElement('canvas');
    faceCanvas.width = width;
    faceCanvas.height = height;
    const faceCtx = faceCanvas.getContext('2d');
    faceCtx.drawImage(
      image,
      x,
      y,
      width,
      height,
      0,
      0,
      width,
      height
    );
    const arrayBuffer = await canvasToArrayBuffer(faceCanvas);
    faceBuffers.push(arrayBuffer);
  }
  return faceBuffers;
};

const canvasToArrayBuffer = async (canvas) => {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      const reader = new FileReader();
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.readAsArrayBuffer(blob);
    });
  });
};

const generateBase64Image = (imgBufferArray) => {
  const binaryString = Array.from(new Uint8Array(imgBufferArray)).map(byte => String.fromCharCode(byte)).join('');
  
  const base64String = btoa(binaryString);

  return `data:image/png;base64,${base64String}`;
};

const actions = {
  async detectFaces ({ commit, state }, inputImage) {

    console.log('start detectFaces detections', inputImage)
    await faceapi.nets.tinyFaceDetector.loadFromUri('/models');

    const options = new faceapi.TinyFaceDetectorOptions({ inputSize: 320, scoreThreshold: 0.5 });
    const detections = await faceapi.detectAllFaces(inputImage, options);

    console.log('detectFaces detections', JSON.stringify(detections))

    if (detections.length != 1) {
      return;
    }

    const faceBuffers = await getFaceBuffers(inputImage, detections);

    console.log('detectFaces faceBuffers', faceBuffers)

    return faceBuffers;
    
  },
  async loadReservations ({ commit, dispatch, state, rootState }, val) {


    const rtn = await appsync.getReservationsByUsername(rootState.session.username, ((new Date).toISOString()).substring(0,10))

    console.log('getReservationsByUsername', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      if (rtn.data.getReservationsByUsername.length > 0) {

        const reservations = rtn.data.getReservationsByUsername.map(item => {
          // item.selectText = item.listingName + ' ' + item.checkInDate
          item.selectText = item.checkInDate
          return item
        })
        console.log('loadReservations', reservations)
        commit('setReservations', reservations)

        if (reservations.length === 1) {
          await dispatch('pickReservation', reservations[0])
        }

      } else {
        throw new Error(Exceptions.CustomNoReservation.message)
      }
    }
  },
  async pickReservation ({ commit, dispatch }, val) {

    commit('setReservation', val)
    await dispatch('loadMembers').catch(e => {
      console.error('loadMembers', e)
      throw e
    })
    
  },
  clearReservation ({ commit }) {

    commit('setReservation', undefined)
    
  },
  async verifyMembers ({ commit, state }, hostVerifying) {

    delete state.reservation.selectText
    // delete state.reservation.notificationResponses

    const rtn = await appsync.verifyMembers(state.reservation, hostVerifying)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      commit('setReservation', rtn.data.verifyMembers)
    }
    
  },
  async loadMembers ({ commit, getters, state }) {

    const rtn = await appsync.getMembers(state.reservation.reservationCode)
    console.log('loadMembers rtn', rtn)
    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const members = rtn.data.getMembers

      if (members.length <= state.reservation.memberCount) {
        const existingMemberNos = members.map((item) => {
          return item.memberNo
        })

        let i = 1
        // console.log('i:', i)
        for (i; i <= state.reservation.memberCount; i++) {
          if (existingMemberNos.indexOf(i) === -1) {
            members.push({ 
              reservationCode: state.reservation.reservationCode, 
              memberNo: i, 
              similarity: 0,
              idImgSrc: require('@/assets/img/id.svg'),
              faceImgSrc: require('@/assets/img/face.svg'),
              idImgKey: null,
              faceImgKey: null,
              keyNotified: false,
              // fullName: null, 
              // gender: null, 
              // birthDate: null, 
              // address: null, 
              // idNumber: null, 
              // nationality: null, 
              // occupation: null, 
              // roomNumber: null, 
            })
          }
        }
      }

      members.sort((a, b) => {
        if (a.memberNo < b.memberNo)
          return -1
        if (a.memberNo > b.memberNo)
          return 1
        return 0
      })

      const resultMembers = await Promise.all(members.map(async (item) => {
        item.idImgSrc = require('@/assets/img/id.svg')

        item.faceImgSrc = require('@/assets/img/face.svg')

        return item

      }));

      commit('setMembers', resultMembers)
    }  

  },
  async saveMember ({ state, commit, dispatch, getters }, imageChanged) {
    console.log('begin state.currentMember', state.currentMember)
    console.log('begin imageChanged', imageChanged)

    // console.log('updateMember', state.currentMember)
    const clonedCurrentMember = Object.assign({}, state.currentMember);
    delete clonedCurrentMember.idImgSrc
    delete clonedCurrentMember.faceImgSrc
    delete clonedCurrentMember.idImgKey
    delete clonedCurrentMember.faceImgKey

    const rtn = await appsync.updateMember(clonedCurrentMember)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      console.log('rtn.data.updateMember', rtn.data.updateMember)
      const member = rtn.data.updateMember

      const index = state.members[member.memberNo - 1]

      state.members[index] = member

      state.currentMember.lastUpdateOn = member.lastUpdateOn
    }

    console.log('allIdVerified', getters.allIdVerified)

    if (getters.allIdVerified) {
      await dispatch('verifyMembers', false)
    }

  },
  async putImage ({ commit, getters, state, rootState}, { imgBufferArray, isId }) {
    // state.currentMember.idMatches = false
    
    if (isId) {
      // state.currentMember.idVerified = false
      const idImgName = `${state.reservation.hostId}/listings/${state.reservation.listingId}/${state.reservation.reservationCode}/id/${state.currentStep}.jpg`
      state.currentMember.idImgKey = `guest_private/${rootState.session.identityId}/${state.reservation.hostId}/listings/${state.reservation.listingId}/${state.reservation.reservationCode}/id/${state.currentStep}.jpg`

      await storage.put(idImgName, imgBufferArray)

      state.currentMember.idImgSrc = generateBase64Image(imgBufferArray)
    } else {
      const faceImgName = `${state.reservation.hostId}/listings/${state.reservation.listingId}/${state.reservation.reservationCode}/face/${state.currentStep}.jpg`
      state.currentMember.faceImgKey = `guest_private/${rootState.session.identityId}/${state.reservation.hostId}/listings/${state.reservation.listingId}/${state.reservation.reservationCode}/face/${state.currentStep}.jpg`

      await storage.put(faceImgName, imgBufferArray)

      state.currentMember.faceImgSrc = generateBase64Image(imgBufferArray)
    }
  },
  setCurrentStep ({ commit, state }, val) {
    commit('setCurrentStep', val)
    commit('setCurrentMember', state.members[val - 1])
  }
  
}

const getters = {
    steps: state => {
      return state.members.length
    },
    currentStep: state => {
      return state.currentStep
    },
    allIdVerified: state => {
      console.log('state.members.length', state.members.length)
  
      let allInputted = false;
      if (state.members.length < state.reservation.memberCount) {
        allInputted = false;
      } else {
        allInputted = state.members.map((memItem) => {
  
          let isAllInputted = false;
          if (memItem.faceImgKey && memItem.idImgKey && memItem.address && memItem.birthDate && memItem.fullName
             && memItem.gender && memItem.idNumber && memItem.nationality 
             && memItem.nextDest && memItem.prevPlace && memItem.occupation) {
            isAllInputted = true;
          }
  
          return isAllInputted;
        }).every(result => result);
      }
  
      return allInputted;
    },
    memberInfoFilled: state => {
      // console.log('state.currentMember', state.currentMember)
      if (state.currentMember) {
        if (!state.currentMember.fullName) {
          return false
        }

        if (!state.currentMember.gender) {
          return false
        }

        if (!state.currentMember.birthDate) {
          return false
        }

        if (!state.currentMember.address) {
          return false
        }

        if (!state.currentMember.idNumber) {
          return false
        }

        if (!state.currentMember.phoneNumber) {
          return false
        }
        
        if (!state.currentMember.nationality) {
          return false
        }

        if (!state.currentMember.occupation) {
          return false
        }

        if (!state.currentMember.prevPlace) {
          return false
        }

        if (!state.currentMember.nextDest) {
          return false
        }

        if (!state.currentMember.faceImgKey) {
          return false
        }

        if (!state.currentMember.idImgKey) {
          return false
        }
        
        return true
      } else {
        return false
      }
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}